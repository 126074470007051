


















































































































































import { PropOptions } from 'vue'
import { WidgetType } from './definition'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import GroundStationIndexSettings from '@/components/data/weatherstation/GroundStationSettings/GroundStationIndexSettings.vue'
import GroundStationDatasetSettings from '@/components/data/weatherstation/GroundStationSettings/GroundStationDatasetSettings.vue'
import OverLimitSettings from '@/components/data/weatherstation/OverLimitSettings/OverLimitSettings.vue'
import SeriesSettings from '@/components/data/weatherstation/SeriesSettings/SeriesSettings.vue'
import { datasetCompare, datasetOverview, datasetWeatherStation } from '@/components/data/weatherstation/SeriesSettings/helper'
import { typesWidgets } from '@/components/ui/WidgetSettings/helper'

export default {
  components: {
    'ui-toggle-switch': ToggleSwitch,
    'ui-button': Button,
    'ui-dialog': Dialog,
    'vue-slider': VueSlider,
    'over-limit-settings': OverLimitSettings,
    'data-ground-station-index-settings': GroundStationIndexSettings,
    'data-ground-station-dataset-settings': GroundStationDatasetSettings,
    'series-settings': SeriesSettings
  },
  props: {
    /**
     * Widget props
     */
    type: {
      type: String
    } as PropOptions<WidgetType>,
    title: {
      type: String,
      required: true
    },
    /**
     * Synchronization
     */
    syncedWithPlayer: {
      type: Boolean,
      required: false
    },
    /**
     * Styles props
     */
    styleBorder: {
      type: Boolean,
      required: false
    },
    stylePadding: {
      type: Boolean,
      required: false
    },
    displayTitle: {
      type: Boolean,
      default: true
    },
    /**
     * Widget settings
     */
    // Weather radar map
    osmOpacity: {
      type: Number,
      required: false
    },
    radarOpacity: {
      type: Number,
      required: false
    },
    displayLayersWeatherStations: {
      type: Boolean,
      required: false
    },
    displayLayersWindArrows: {
      type: Boolean,
      required: false
    },
    displayLayersRainpath: {
      type: Boolean,
      required: false
    },
    // Ground station chart
    stationIndex: {
      type: Number,
      required: false
    },
    stationDataset: {
      type: Object,
      default: () => ({})
    },
    displaySession: {
      type: Boolean,
      default: true
    },
    /**
     * Limit settings
     */
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      default: () => ([])
    },
    windOverLimit: {
      type: Array,
      default: () => ([])
    },
    gustsOverLimit: {
      type: Array,
      default: () => ([])
    },
    humidityOverLimit: {
      type: Number,
      required: false
    },
    trackMinOverLimit: {
      type: Number,
      required: false
    },
    trackMaxOverLimit: {
      type: Number,
      required: false
    },
    seriesOverview: {
      type: Array,
      default: () => ([])
    },
    seriesCompare: {
      type: Array,
      default: () => ([])
    },
    currentStation: {
      type: Number,
      default: 0
    },
    stationSet: {
      type: Array,
      default: () => ([])
    },
    /**
    * Additional
    */
    isPlayerInDashboard: {
      type: Boolean,
      default: false
    },
    displayWBGTAndHeatIndex: {
      type: Boolean,
      default: false
    },
    displayLightIntensity: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typesWidgets,
      widgetParamsByType: {
        'weather-radar-map': [{
          label: 'Background layer opacity',
          name: 'osmOpacity',
          component: 'vue-slider',
          componentParams: {
            max: 1,
            interval: 0.1
          },
          valueProp: 'value',
          eventToListen: 'change',
          eventFunction: (newValue) => {
            this.$emit('update:osmOpacity', newValue)
          }
        }, {
          label: 'Radar layer opacity',
          name: 'radarOpacity',
          component: 'vue-slider',
          componentParams: {
            max: 1,
            interval: 0.1
          },
          valueProp: 'value',
          eventToListen: 'change',
          eventFunction: (newValue) => {
            this.$emit('update:radarOpacity', newValue)
          }
        }, {
          label: 'Ground stations',
          name: 'displayLayersWeatherStations',
          component: 'ui-toggle-switch',
          valueProp: 'checked',
          eventToListen: 'click',
          eventFunction: () => {
            this.$emit('update:displayLayersWeatherStations', !this.$props.displayLayersWeatherStations)
          }
        }, {
          label: 'Wind arrows',
          name: 'displayLayersWindArrows',
          component: 'ui-toggle-switch',
          valueProp: 'checked',
          eventToListen: 'click',
          eventFunction: () => {
            this.$emit('update:displayLayersWindArrows', !this.$props.displayLayersWindArrows)
          }
        }, {
          label: 'Rainpath',
          name: 'displayLayersRainpath',
          component: 'ui-toggle-switch',
          valueProp: 'checked',
          eventToListen: 'click',
          eventFunction: () => {
            this.$emit('update:displayLayersRainpath', !this.$props.displayLayersRainpath)
          }
        }],
        'ground-station-chart': [{
          label: 'Station',
          name: 'stationIndex',
          component: 'data-ground-station-index-settings',
          valueProp: 'value',
          eventToListen: 'change',
          eventFunction: (e) => {
            this.$emit('update:stationIndex', +e)
          }
        }, {
          label: 'Display Session',
          name: 'displaySession',
          component: 'ui-toggle-switch',
          valueProp: 'checked',
          eventToListen: 'click',
          eventFunction: (value) => {
            this.$emit('update:displaySession', value)
          }
        }, {
          label: 'Dataset(s) (2 different units max.)',
          name: 'stationDataset',
          component: 'data-ground-station-dataset-settings',
          valueProp: 'stationDataset',
          componentParams: {
            displayLightIntensity: this.displayLightIntensity,
            displayWBGTAndHeatIndex: this.displayWBGTAndHeatIndex
          },
          eventToListen: 'input',
          eventFunction: ($event) => {
            const isDatasetWindDirectionAlreadySet = this.$props.stationDataset.datasetWindDirection

            /**
             * If there is already the datasetWindDirection set,
             * as only this serie is authorized to be displayed,
             * we don't take in consideration other series enabled / disabled
             */
            if (isDatasetWindDirectionAlreadySet && $event.key !== 'datasetWindDirection') return

            const newStationDataSet = {
              ...this.$props.stationDataset,
              [$event.key]: $event.value
            }
            /**
             * We need to manage several edge case :
             * * if key = datasetWindDirection, we need to remove all other series
             * * if there is more than two keys already enabled, nothing happen
             */
            if (
              $event.key === 'datasetWindDirection' &&
              $event.value === true
            ) {
              Object.keys(newStationDataSet).forEach(currentKey => {
                if (currentKey !== 'datasetWindDirection') {
                  newStationDataSet[currentKey] = false
                }
              })
            }
            // Todo: To remove/improve after feedback client
            // const numberOfSeriesEnabled = Object.keys(newStationDataSet)
            //   .filter(currentKey => newStationDataSet[currentKey])
            //   .length
            // if (numberOfSeriesEnabled <= 2) this.$emit('update:stationDataset', newStationDataSet)
            this.$emit('update:stationDataset', newStationDataSet)
          }
        }],
        'ground-station-chart-multi-station': [{
          label: 'Station',
          name: 'stationSet',
          component: 'series-settings',
          componentParams: {
            dataset: datasetWeatherStation
          },
          valueProp: 'series',
          eventToListen: 'input',
          eventFunction: ($event) => {
            const indicators = [...this.stationSet]
            if ($event.value) {
              indicators.push($event.key)
            } else {
              const indexIndicator = indicators.findIndex(indicator => indicator === $event.key)
              indicators.splice(indexIndicator, 1)
            }
            this.$emit('update:stationSet', indicators)
          }
        }, {
          label: 'Display Session',
          name: 'displaySession',
          component: 'ui-toggle-switch',
          valueProp: 'checked',
          eventToListen: 'click',
          eventFunction: (value) => {
            this.$emit('update:displaySession', value)
          }
        }, {
          label: 'Dataset',
          name: 'stationDataset',
          component: 'data-ground-station-dataset-settings',
          componentParams: {
            allowWindDirectionSet: false,
            onlyOneDataset: true,
            displayLightIntensity: this.displayLightIntensity,
            displayWBGTAndHeatIndex: this.displayWBGTAndHeatIndex
          },
          valueProp: 'stationDataset',
          eventToListen: 'input',
          eventFunction: ($event) => {
            /**
             * For multi station, only one serie is allowed.
             * We set to false every serie in the dataset,
             * and set to true the one the $event.key emit
             */
            const newStationDataSet = {
              ...this.$props.stationDataset
            }
            Object.keys(newStationDataSet).forEach(function (k) {
              newStationDataSet[k] = false
            })
            newStationDataSet[$event.key] = true
            this.$emit('update:stationDataset', newStationDataSet)
          }
        }],
        'event-overview': [
          {
            label: 'All available parameters:',
            name: 'seriesOverview',
            component: 'series-settings',
            componentParams: {
              dataset: datasetOverview
            },
            valueProp: 'series',
            eventToListen: 'input',
            eventFunction: ($event) => {
              const indicators = [...this.seriesOverview]
              if ($event.value) {
                indicators.push($event.key)
              } else {
                const indexIndicator = indicators.findIndex(indicator => indicator === $event.key)
                indicators.splice(indexIndicator, 1)
              }
              this.$emit('update:seriesOverview', indicators)
            }
          }
        ],
        'compare-sessions': [
          {
            label: 'All available parameters:',
            name: 'seriesCompare',
            component: 'series-settings',
            componentParams: {
              dataset: datasetCompare
            },
            valueProp: 'series',
            eventToListen: 'input',
            eventFunction: ($event) => {
              const indicators = [...this.seriesCompare]
              if ($event.value) {
                indicators.push($event.key)
              } else {
                const indexIndicator = indicators.findIndex(indicator => indicator === $event.key)
                indicators.splice(indexIndicator, 1)
              }
              this.$emit('update:seriesCompare', indicators)
            }
          }
        ],
        'weather-station-listing': [
          {
            label: 'Default station',
            name: 'currentStation',
            component: 'data-ground-station-index-settings',
            valueProp: 'value',
            eventToListen: 'change',
            eventFunction: (value) => {
              this.$emit('update:currentStation', +value)
            }
          }
        ],
        'weather-station-listing-multi-station': [
          {
            label: 'Station(s) displayed',
            name: 'stationSet',
            component: 'series-settings',
            componentParams: {
              dataset: datasetWeatherStation
            },
            valueProp: 'series',
            eventToListen: 'input',
            // eventFunction: (value) => {
            //   this.$emit('update:currentStation', +value)
            // }
            eventFunction: ($event) => {
              const indicators = [...this.stationSet]
              if ($event.value) {
                indicators.push($event.key)
              } else {
                const indexIndicator = indicators.findIndex(indicator => indicator === $event.key)
                indicators.splice(indexIndicator, 1)
              }
              this.$emit('update:stationSet', indicators)
            }
          }
        ],
        forecast: [
          {
            label: 'Air Temperature',
            name: 'temperatureOverLimit',
            component: 'input',
            componentParams: {
              type: 'number'
            },
            valueProp: 'value',
            eventToListen: 'change',
            eventFunction: ($event) => {
              this.$emit('update:temperatureOverLimit', $event.target.value ? +$event.target.value : null)
            }
          },
          {
            label: 'Chance of Rain',
            name: 'chanceOfRainOverLimit',
            component: 'over-limit-settings',
            componentParams: {
              options: ['20%', '40%', '60%', '80%', 'more than 80%', '100%'],
              text: 'Select all values above the desired threshold to highlight them.'
            },
            valueProp: 'value',
            eventToListen: 'input',
            eventFunction: ($event) => {
              this.$emit('update:chanceOfRainOverLimit', $event)
            }
          },
          {
            label: 'Wind',
            name: 'windOverLimit',
            component: 'over-limit-settings',
            componentParams: {
              options: ['0 to 10', '5 to 15', '10 to 20', '15 to 25', '20 to 30', '30 to 40', '40 to 50', '50 to 60', '60 to 80', '80 to 100', ' 100 to 120', '120 to 150', '150 to 200', 'more than 200'],
              text: 'Select all values above the desired threshold to highlight them.'
            },
            valueProp: 'value',
            eventToListen: 'input',
            eventFunction: ($event) => {
              this.$emit('update:windOverLimit', $event)
            }
          },
          {
            label: 'Gusts',
            name: 'gustsOverLimit',
            component: 'over-limit-settings',
            componentParams: {
              options: ['0 to 10', '5 to 15', '10 to 20', '15 to 25', '20 to 30', '30 to 40', '40 to 50', '50 to 60', '60 to 80', '80 to 100', ' 100 to 120', '120 to 150', '150 to 200', 'more than 200'],
              text: 'Select all values above the desired threshold to highlight them.'
            },
            valueProp: 'value',
            eventToListen: 'input',
            eventFunction: ($event) => {
              this.$emit('update:gustsOverLimit', $event)
            }
          },
          {
            label: 'Humidity',
            name: 'humidityOverLimit',
            component: 'input',
            componentParams: {
              type: 'number'
            },
            valueProp: 'value',
            eventToListen: 'change',
            eventFunction: ($event) => {
              this.$emit('update:humidityOverLimit', $event.target.value ? +$event.target.value : null)
            }
          },
          {
            label: 'Track Min Temperature',
            name: 'trackMinOverLimit',
            component: 'input',
            componentParams: {
              type: 'number'
            },
            valueProp: 'value',
            eventToListen: 'change',
            eventFunction: ($event) => {
              this.$emit('update:trackMinOverLimit', $event.target.value ? +$event.target.value : null)
            }
          },
          {
            label: 'Track Max Temperature',
            name: 'trackMaxOverLimit',
            component: 'input',
            componentParams: {
              type: 'number'
            },
            valueProp: 'value',
            eventToListen: 'change',
            eventFunction: ($event) => {
              this.$emit('update:trackMaxOverLimit', $event.target.value ? +$event.target.value : null)
            }
          }
        ]
      }
    }
  },
  methods: {
    canWidgetSync (itemType) {
      return ['ground-station-chart', 'forecast', 'event-overview', 'compare-sessions', 'player', 'unknown'].includes(
        itemType)
    }
  }
}
